import styled from 'styled-components'
import { BackgroundPosition } from './HeroBanner.types'
import { Breakpoint } from 'shared/hooks/useBreakpoint'

export const HEIGHT_MAP: Record<Breakpoint, number> = {
  xl: 600,
  lg: 550,
  md: 500,
  sm: 450
}

export const Container = styled.div<{
  bg: string
  bgPosition: BackgroundPosition
  breakpoint: Breakpoint
}>`
  background-image: url(${({ bg }) => bg});
  background-position: ${({ bgPosition }) =>
    bgPosition.backgroundPositionMobile};
  background-repeat: no-repeat;
  padding: 2.5rem 0;
  margin: 0 auto;

  @media (min-width: 768px) {
    padding: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: ${({ breakpoint }) => HEIGHT_MAP[breakpoint]}px;
    background-position: ${({ bgPosition }) =>
      bgPosition.backgroundPositionDesktop};
  }
`

export const HeroImage = styled.img<{ breakpoint: Breakpoint }>`
  object-fit: cover;
  width: 100%;
  height: ${({ breakpoint }) => HEIGHT_MAP[breakpoint]}px;
`

export const Content = styled.div`
  max-width: 1192px;
  padding: 0 1rem;
  gap: 1.25rem;
`

export const Title = styled.h1`
  font-weight: 600;
  text-align: center;
  color: ${({ color }) => color};

  @media (min-width: 768px) {
    font-size: 64px;
    line-height: 72px;
  }

  @media (max-width: 1100px) {
    font-size: 56px;
    line-height: 64px;
  }

  @media (max-width: 910px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

export const Subtitle = styled.h2`
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  color: ${({ color }) => color};
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    justify-content: center;

    a {
      max-width: 420px;
    }
  }
`
